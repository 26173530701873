<template>
    <div class="col-12">
        <div class="col-12 text-center">
            <span><font-awesome-icon icon="blind" class="icon" /></span>
            <h1>{{$t('sorry')}}</h1>
            <p v-html="$t('page-not-found')"></p>
        </div>
    </div>
</template>

<script>
    export default {
        name: 'NotFound',
    }
</script>

<style scoped></style>